import { useState } from 'react';

const Education = () => {
    const [expandedIndex, setExpandedIndex] = useState(null);

    const handleRowClick = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index); // Toggle the clicked row
    };

    const education = [
        {
            qualification: "Bachelor of Engineering in Computer Science",
            institution: "Birla Institute of Technology & Science, Pilani",
            grade: '6.81/10',
            years: "2022"
        },
        {
            qualification: "12th Grade (Science)",
            institution: "Telangana State Board of Intermediate Education",
            grade: '9.76/10',
            years: "2018"
        },
        {
            qualification: "10th Grade",
            institution: "Telangana State Board of Secondary Education",
            grade: '9.80/10',
            years: "2016"
        }
    ];

    return (
        <div className="flex flex-col w-full h-full bg-black pl-4 pr-4 xl:pl-12 xl:pr-10 pt-10 md:pt-6 xl:pt-8">
            <h1 className="font-mono text-white text-[22px] md:text-[32px] lg:text-[44px] xl:text-[32px]">Education</h1>

            {/* Table for larger screens */}
            <table className="min-w-full border-collapse mt-4 hidden md:table">
                <thead>
                    <tr>
                        <th className="border-b border-r border-dotted border-gray-400 px-4 py-2 text-left text-white font-mono">Qualification</th>
                        <th className="border-b border-r border-dotted border-gray-400 px-4 py-2 text-left text-white font-mono">Institution</th>
                        <th className="border-b border-r border-dotted border-gray-400 px-4 py-2 text-left text-white font-mono">Grade</th>
                        <th className="border-b border-dotted border-gray-400 px-4 py-2 text-left text-white font-mono">Graduation</th>
                    </tr>
                </thead>
                <tbody>
                    {education.map((education, index) => (
                        <>
                            <tr key={index} onClick={() => handleRowClick(index)} className="cursor-pointer">
                                <td className="border-b border-r border-dotted border-gray-400 px-4 py-2 text-white font-mono">{education.qualification}</td>
                                <td className="border-b border-r border-dotted border-gray-400 px-4 py-2 text-white font-mono">{education.institution}</td>
                                <td className="border-b border-r border-dotted border-gray-400 px-4 py-2 text-white font-mono">{education.grade}</td>
                                <td className="border-b border-r border-dotted border-gray-400 px-4 py-2 text-white font-mono">{education.years}</td>
                            </tr>
                            {/* {expandedIndex === index && (
                                <tr>
                                    <td colSpan="4" className="px-4 py-4 text-white font-mono bg-gray-800">
                                        <div className="flex flex-row gap-x-3">
                                            <p>Relevant Links: </p>
                                            <a href={job.link} target="_blank" rel="noopener noreferrer" className="underline text-red-500">
                                                {job.linkLabel}
                                            </a>
                                        </div>
                                        <ul className="list-disc list-inside">
                                            {job.points.map((point, i) => (
                                                <li key={i}>{point}</li>
                                            ))}
                                        </ul>

                                    </td>
                                </tr>
                            )} */}
                        </>
                    ))}
                </tbody>
            </table>

            {/* Stacked layout for mobile */}
            <div className="block md:hidden mt-4 space-y-6">
                {education.map((education, index) => (
                    <div key={index} className="border border-dotted border-gray-400 p-4" onClick={() => handleRowClick(index)}>
                        <p className="text-white font-mono text-lg">{education.qualification}</p>
                        <p className="text-white font-mono">Institution: {education.institution}</p>
                        <p className="text-white font-mono">Grade: {education.grade}</p>
                        <p className="text-white font-mono">Graduated: {education.years}</p>
                        {/* <div className="flex flex-row gap-x-3">
                            <p className="text-white font-mono">Link: </p>
                            <a href={job.link} target="_blank" rel="noopener noreferrer" className="underline text-red-500">
                                {job.linkLabel}
                            </a>
                        </div> */}
                        {/* {expandedIndex === index && (

                            <ul className="list-disc list-inside mt-2 text-white font-mono">
                                {job.points.map((point, i) => (
                                    <li key={i}>{point}</li>
                                ))}
                            </ul>
                        )} */}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Education;
