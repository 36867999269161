import { useState } from 'react';

const WorkExperience = () => {
    const [expandedIndex, setExpandedIndex] = useState(null);

    const handleRowClick = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index); // Toggle the clicked row
    };

    const experiences = [
        {
            org: "Finurja (Artha Astra Inc.)",
            role: "Founding Engineer",
            link: "https://www.finurja.com",
            linkLabel: "Finurja.com",
            points: [
                'Engineered the entire tech from scratch, scaling seamlessly as the user base grew from 0 to 10K+ users',
                'Developed scalable, high-performance REST API’s using the Golang gin framework, ensuring optimal data flow',
                'Integrated serverless functions into the architecture to handle resource-intensive tasks asynchronously',
                'Automated complex tasks using Python scripts and libraries such as Selenium, Scrapy, PyPDF etc',
                'Contributed significantly to the development of mobile application using Flutter, following best practices',
                'Developed the entire website from scratch using React & Tailwind, ensuring seamless performance across devices',
                'Managed and scaled the back-end infrastructure via AWS services like ECS, RDS, Lambda, SNS, SQS, S3',
                'Rolled out new features quickly & efficiently using AWS codepipeline, GitHub actions, Circle CI etc',
                'Gained access to restricted Gmail API’s by implementing best security practices to protect sensitive data'
            ],
            period: "Mar'22 - Present"
        },
        {
            org: "Audify",
            role: "Co-Founder & CTO",
            link: "https://play.google.com/store/apps/details?id=com.audify",
            linkLabel: "Audify @ GooglePlay",
            points: [
                'Founded Audify, a platform enabling students to connect with experts across fields for guidance and mentorship',
                'Gained significant traction with 5,000+ users in just 90 days post-launch, backed by 100+ experts',
                'Designed and developed the Android app using Figma, Java & Firebase, ensuring a seamless user experience',
                'Implemented efficient audio streaming in the app, enabling seamless consumption of over 500+ hours of content',
                'Shutdown after 6 months of operations as there was limited demand for paid mentorship services among students'
            ],
            period: "Oct'21 - Feb'22"
        },
        {
            org: "DesignCode Labs",
            role: "Co-Founder & CTO",
            link: "https://yourstory.com/companies/designcode-labs",
            linkLabel: "DesignCode Labs @ YS",
            points: [
                'Transitioned from a freelancer to founding a professional services company generating over |1M in annual revenue',
                'Collaborated with 20+ startups across sectors including FinTech, HealthTech & others to build their MVP’s',
                'Led end-to-end project execution, involving requirements gathering, UI/UX collaboration with designers, active development with the tech team, and successful product delivery to clients.',
                'Worked with technologies like Android, React Native, Flutter, MERN, DjangoREST, Postgres & others'
            ],
            period: "Apr'20 - Oct'22"
        },
        {
            org: "iCART (India COVID-19 Apex Research Team)",
            role: "Volunteer Software Engineer",
            link: "https://github.com/CovidToday",
            linkLabel: "CovidToday @ Github",
            points: [
                'Collaborated with a team of doctors to develop software tools to tackle complex problems during COVID-19',
                'Led the backend development of a data collection project using PHP Laravel & MySQL, funded by AIIMS',
                'Assisted in developing a dashboard to generate data-based policy recommendations for vaccination, travel etc',
                'Created a portal using React JS for educational institutions to evaluate the risk of reopening amid COVID-19',
                'Wrote Python scripts to analyze the COVID data to get insights and predictions using machine learning models'
            ],
            period: "Apr'20 - May'21"
        },
        {
            org: "Licious",
            role: "Software Engineer Intern",
            link: "https://play.google.com/store/apps/details?id=com.licious",
            linkLabel: "Licious @ GooglePlay",
            points: [
                'Contributed to both Low-Level and High-Level Design phases during the rewrite of the order handling service',
                'Developed RESTful APIs using Spring Boot, MySQL, and MVC as a key member of the orders service team',
                'Used various creational, structural and behavioural design patterns in Java to write clean and optimized code'
            ],
            period: "Jul'21 - Dec'21"
        },
        {
            org: "E-Connect Solutions",
            role: "Software Engineer Intern",
            link: "https://www.e-connectsolutions.com",
            linkLabel: "econnectsolutions.com",
            points: [
                'Developed an internal tool using React JS to streamline & manage the software development life cycle efficiently',
                "Integrated REST API's to dynamically display data on the front end & handle data persistence on the backend",
            ],
            period: "May'20 - Jul'20"
        },
    ];

    return (
        <div className="flex flex-col w-full h-full bg-black pl-4 pr-4 xl:pl-12 xl:pr-10 pt-10 md:pt-6 xl:pt-8">
            <h1 className="font-mono text-white text-[22px] md:text-[32px] lg:text-[44px] xl:text-[32px]">Work Experience</h1>

            {/* Table for larger screens */}
            <table className="min-w-full border-collapse mt-4 hidden md:table">
                <thead>
                    <tr>
                        <th className="border-b border-r border-dotted border-gray-400 px-4 py-2 text-left text-white font-mono">Organisation</th>
                        <th className="border-b border-r border-dotted border-gray-400 px-4 py-2 text-left text-white font-mono">Role</th>
                        <th className="border-b border-dotted border-gray-400 px-4 py-2 text-left text-white font-mono">Timeline</th>
                    </tr>
                </thead>
                <tbody>
                    {experiences.map((job, index) => (
                        <>
                            <tr key={index} onClick={() => handleRowClick(index)} className="cursor-pointer">
                                <td className="border-b border-r border-dotted border-gray-400 px-4 py-2 text-white font-mono">{job.org}</td>
                                <td className="border-b border-r border-dotted border-gray-400 px-4 py-2 text-white font-mono">{job.role}</td>
                                <td className="border-b border-r border-dotted border-gray-400 px-4 py-2 text-white font-mono">{job.period}</td>
                            </tr>
                            {expandedIndex === index && (
                                <tr>
                                    <td colSpan="4" className="px-4 py-4 text-white font-mono bg-gray-800">
                                        <div className="flex flex-row gap-x-3">
                                            <p>Relevant Links: </p>
                                            <a href={job.link} target="_blank" rel="noopener noreferrer" className="underline text-red-500">
                                                {job.linkLabel}
                                            </a>
                                        </div>
                                        <ul className="list-disc list-inside">
                                            {job.points.map((point, i) => (
                                                <li key={i}>{point}</li>
                                            ))}
                                        </ul>

                                    </td>
                                </tr>
                            )}
                        </>
                    ))}
                </tbody>
            </table>

            {/* Stacked layout for mobile */}
            <div className="block md:hidden mt-4 space-y-6">
                {experiences.map((job, index) => (
                    <div key={index} className="border border-dotted border-gray-400 p-4" onClick={() => handleRowClick(index)}>
                        <p className="text-white font-mono text-lg">{job.org}</p>
                        <p className="text-white font-mono">Role: {job.role}</p>
                        <p className="text-white font-mono">Period: {job.period}</p>
                        <div className="flex flex-row gap-x-3">
                            <p className="text-white font-mono">Link: </p>
                            <a href={job.link} target="_blank" rel="noopener noreferrer" className="underline text-red-500">
                                {job.linkLabel}
                            </a>
                        </div>
                        {expandedIndex === index && (

                            <ul className="list-disc list-inside mt-2 text-white font-mono">
                                {job.points.map((point, i) => (
                                    <li key={i}>{point}</li>
                                ))}
                            </ul>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WorkExperience;
