import React, { useState, useEffect, useCallback } from 'react';
import HeroSection from "../components/Sections/Hero";
import WorkExperience from "../components/Sections/Experience";
import Education from '../components/Sections/Education';

const sections = [
    { id: 'hero', Component: HeroSection },
    { id: 'experience', Component: WorkExperience },
    { id: 'education', Component: Education }
];

const debounce = (fn, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            fn(...args);
        }, delay);
    };
};

const HomePage = () => {
    const [currentSectionIndex, setCurrentSectionIndex] = useState(0);

    const goToNextSection = useCallback(() => {
        if (currentSectionIndex < sections.length - 1) {
            setCurrentSectionIndex(prevIndex => prevIndex + 1);
        }
    }, [currentSectionIndex]);

    const goToPreviousSection = useCallback(() => {
        if (currentSectionIndex > 0) {
            setCurrentSectionIndex(prevIndex => prevIndex - 1);
        }
    }, [currentSectionIndex]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'ArrowDown') {
                goToNextSection();
            } else if (e.key === 'ArrowUp') {
                goToPreviousSection();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [goToNextSection, goToPreviousSection]);

    useEffect(() => {
        const handleWheel = debounce((e) => {
            e.preventDefault();
            if (e.deltaY > 0) {
                goToNextSection();
            } else if (e.deltaY < 0) {
                goToPreviousSection();
            }
        }, 100);

        window.addEventListener('wheel', handleWheel, { passive: false });
        return () => window.removeEventListener('wheel', handleWheel);
    }, [goToNextSection, goToPreviousSection]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        });
    }, [currentSectionIndex]);

    return (
        <div>
            <div className='flex flex-col w-full h-full xl:hidden'>
                <HeroSection />
                <WorkExperience />
                <Education />
            </div>
            <div className="relative w-screen h-screen bg-black overflow-hidden hidden xl:flex">
                {sections.map(({ id, Component }, index) => (
                    <div
                        key={id}
                        className={`absolute w-full h-full transition-transform duration-500 ease-in-out ${index === currentSectionIndex
                            ? 'translate-y-0'
                            : index < currentSectionIndex
                                ? '-translate-y-full'
                                : 'translate-y-full'
                            }`}
                    >
                        <Component />
                    </div>
                ))}

                <div className="absolute right-4 top-1/3 transform -translate-y-1/2 flex flex-col gap-4">
                    <button
                        onClick={goToPreviousSection}
                        disabled={currentSectionIndex === 0}
                        className={`p-2 rounded-full ${currentSectionIndex === 0 ? 'bg-gray-500' : 'bg-white'
                            }`}
                    >
                        ↑
                    </button>
                    <button
                        onClick={goToNextSection}
                        disabled={currentSectionIndex === sections.length - 1}
                        className={`p-2 rounded-full ${currentSectionIndex === sections.length - 1 ? 'bg-gray-500' : 'bg-white'
                            }`}
                    >
                        ↓
                    </button>
                </div>
            </div>
        </div>
    );
};

export default HomePage;
