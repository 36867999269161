import React, { useState, useEffect, useRef } from 'react';
import { Menu } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import github from '../../assets/github.svg'
import linkedin from '../../assets/linkedin.svg'

const Navbar = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const drawerRef = useRef(null);
    const navigate = useNavigate();

    const toggleDrawer = () => setIsDrawerOpen(prev => !prev);

    const handleClickOutside = (event) => {
        if (drawerRef.current && !drawerRef.current.contains(event.target)) {
            setIsDrawerOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const navLinks = [
        // { name: 'Work Experience' },
        // { name: 'Projects' },
        // { name: 'Open Source', },
        // { name: 'Competitions' },
    ];


    return (
        <nav className="bg-black shadow-white shadow-sm flex w-full sticky top-0 z-30">
            {/* Desktop Navbar */}
            <div className="hidden xl:flex flex-row w-full items-center justify-between my-6 px-12">
                <div className="flex ustify-start items-center gap-x-20">
                    <h className="font-mono text-[38px] text-white">Vineeth Kumar's</h>
                    {navLinks.map(({ name, href, onClick }, index) => (
                        <div key={index}>
                            <a
                                href={href || "#"}
                                onClick={onClick}
                                className="nav-link flex items-center font-mono font-normal text-[18px] text-white"
                            >
                                {name}
                            </a>
                        </div>
                    ))}
                </div>
                <div className='flex gap-x-4'>
                    <a href='https://github.com/vineeth-vk11' target="_blank" rel="noopener noreferrer">
                        <img
                            className='h-[36px]'
                            src={github}
                        />
                    </a>
                    <a>
                        <img
                            className='h-[36px]'
                            src={linkedin}
                        />
                    </a>
                </div>
            </div>

            {/* Mobile Navbar */}
            <div className="xl:hidden w-full mx-3 my-4 flex flex-row items-center justify-between relative">
                <div className='flex items-center gap-x-2 md:gap-x-4'>
                    <button onClick={toggleDrawer} className="p-2">
                        <Menu className="h-6 w-6 md:h-8 md:w-8 lg:w-10 lg:h-10 text-white" />
                    </button>
                    <h className="font-mono text-[22px] md:text-[32px] lg:text-[38px] text-white">Vineeth Kumar</h>
                </div>
                <div className='flex items-center gap-x-2'>
                    <a href='https://github.com/vineeth-vk11' target="_blank" rel="noopener noreferrer">
                        <img
                            className='h-[24px] md:h-[32px] lg:h-[36px]'
                            src={github}
                        />
                    </a>
                    <a href='https://www.linkedin.com/in/vineeth-kumar/' target="_blank" rel="noopener noreferrer">
                        <img
                            className='h-[24px] md:h-[32px] lg:h-[36px]'
                            src={linkedin}
                        />
                    </a>
                </div>
            </div>

            {/* Drawer Menu */}
            <div
                ref={drawerRef}
                className={`fixed top-0 left-0 w-64 h-full bg-black shadow-white shadow-sm transform ${isDrawerOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out xl:hidden`}
            >
                <div className="p-4">
                    <button onClick={toggleDrawer} className="text-white">
                        <Menu className="h-6 w-6" />
                    </button>
                    <ul className="mt-8 space-y-4">
                        {navLinks.map(({ name, href, onClick }, index) => (
                            <li key={index}>
                                <a href={href || "#"} onClick={onClick} className="block text-white font-mono text-[16px] md:text-[18px] lg:text-[20px]">{name}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
