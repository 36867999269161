import React, { useState } from 'react';
import me from '../../assets/me.png';

const points = [
    "~ Started coding at 15 to hack online games",
    "~ Gained experience by building apps for college student clubs",
    {
        text: "~ Founded ",
        link: {
            href: "https://yourstory.com/companies/designcode-labs",
            label: "DesignCode Labs",
        },
        suffix: ", a software services company"
    },
    "~ Collaborated with 20+ idea-stage founders to build their MVPs",
    "~ Grew the company to an annual revenue of ₹10 Lakhs",
    "~ Shut down, services is tough and not rewarding enough",
    {
        text: "~ Founded ",
        link: {
            href: "https://play.google.com/store/apps/details?id=com.audify",
            label: "Audify",
        },
        suffix: ", a platform providing career advice from experts"
    },
    "~ Onboarded over 200 experts who advised 5000+ users",
    "~ Shut down, students were broke and could not afford experts",
    {
        text: "~ Currently working at a fintech startup ",
        link: {
            href: "https://www.finurja.com",
            label: "Finurja",
        },
        suffix: " as a founding engineer"
    },
    "~ Joined the team at the idea stage as the first engineer hire",
    "~ Developed the entire product from scratch alongside CTO",
    "~ The product is now used by over 10,000 users for wealth management",
];

const HeroSection = () => {
    const [showAllPoints, setShowAllPoints] = useState(false);

    const handleTogglePoints = () => {
        setShowAllPoints(!showAllPoints); // Toggle between true and false
    };

    const displayedPoints = showAllPoints || window.innerWidth >= 768 ? points : points.slice(0, 7);

    return (
        <div className="flex flex-col xl:flex-row w-full h-full bg-black pt-4 md:pt-6 xl:pt-8">
            <div className="flex w-[100%] flex-col h-full justify-left pl-4 pr-4 xl:pl-12 xl:pr-10">
                <h1 className="font-mono text-white text-[22px] md:text-[32px] lg:text-[44px] xl:text-[32px] mt-4 xl:mt-0">Journey So Far ...</h1>
                <ul className="list-none pl-2 xl:pl-8 mt-4 space-y-2 text-[16px] md:text-[18px] font-mono text-white">
                    {displayedPoints.map((point, index) => (
                        <li key={index}>
                            {typeof point === 'string' ? point : (
                                <>
                                    {point.text}
                                    <a href={point.link.href} target="_blank" rel="noopener noreferrer" className="underline text-red-500">
                                        {point.link.label}
                                    </a>
                                    {point.suffix && point.suffix}
                                </>
                            )}
                        </li>
                    ))}
                </ul>
                {window.innerWidth < 768 && (
                    <button onClick={handleTogglePoints} className="text-red-500 underline mt-4">
                        {showAllPoints ? "Read Less" : "Read More"}
                    </button>
                )}
            </div>
        </div>
    );
};

export default HeroSection;
